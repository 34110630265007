import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory } from 'vue-router'
import { ActiveUser } from '@/services/user';
import {IS_MZ_BRANDING} from "@/constants/config";
import store from "./store";
import { FilesService } from '@/services/files'
import { UserManagementService } from '@/services/user_management';
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { ROLES } from './roles';
import { UsersService } from '@/services/users';

// Containers
const TheContainer = () => import('@/containers/TheContainer');
const AccountContainer = () => import('@/containers/account/AccountContainer');

// Views
const Home = () => import('@/views/Home');
const About = () => import('@/views/About')
const Information = () => import('@/views/Information')
const Destinations = () => import('@/views/Destinations')
const Country = () => import('@/views/Country')
const Flyers = () => import('@/views/Flyers')
const Flyer = () => import('@/views/Flyer')
const Privacy = () => import('@/views/Privacy')
const GetInTouch = () => import('@/views/GetInTouch')
const MeIn = () => import('@/views/MeIn')
const Offers = () => import('@/views/Offers')
const ListOffers = () => import('@/views/offers/ListOffers')
const CreateOffer = () => import('@/views/offers/CreateOffer')
const MyTasks = () => import('@/views/offers/MyTasks')
const OfferDetails = () => import('@/views/offers/OfferDetails')
const SavedOffers = () => import('@/views/offers/SavedOffers')
const Interested = () => import('@/views/offers/Interested') 
const Applicants = () => import('@/views/offers/Applicants') 
// account nested Views
const Page404 = () => import('@/views/Page404');
const Login = () => import('@/views/account/Login');
const ForgotPassword = () => import('@/views/account/ForgotPassword');
const ResetPassword = () => import('@/views/account/ResetPassword');

// Users
const Users = () => import('@/views/users/UsersView');
const User = () => import('@/views/users/UserView');
const Profile = () => import('@/views/users/ProfileComponent')
const JobPage = () => import('@/views/users/JobPage')
const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes: configRoutes()
});

const isOpenAccess = (route) =>
  route.matched.some((route) => route.meta?.isOpenAccess);

const isFound = (route) => route.matched[0].name !== "NotFound";
   const setUserRole=async()=>{
    const {role,office}= await UsersService.getActiveUserInfo();
switch (role) {
 case 1:
   store.commit('setUserRole',ROLES.FLYER)
   break;
   case 2:
   store.commit('setUserRole',ROLES.MANAGER)
   break;
   case 3:
   store.commit('setUserRole',ROLES.AMBASSADOR)
   break;
 default:
 store.commit('setUserRole',ROLES.FLYER)
   break;
}
store.commit('setOffice',office)
   }
router.beforeEach(async (to, from, next) => {
  if (to.meta?.getTitle) to.meta.title = to.meta.getTitle(to)
  const isAuthenticated = ActiveUser.get();

	if (!ActiveUser.getToken()) {
		const urlParams = new URLSearchParams(window.location.search);
		const gateKey = 'code';

		try {
			if (urlParams.has(gateKey)) {
				const code = urlParams.get('code');
				const { data, res } = await UserManagementService.check_gate(code);

				if (!res.ok) {
					alert('You are not allowed to login to the Flyer  Programme. Please write to your local Admin.');
          if (window.location.pathname !== '/account/login_management') {
            window.location.href  = `${window.location.origin}/account/login_management`;
          }
				}
        await ActiveUser.set({ accessToken: data.access_token, email: data.email });
        if (localStorage.getItem('pathToLoadAfterLogin')) {
          const path = localStorage.getItem('pathToLoadAfterLogin');
          localStorage.removeItem('pathToLoadAfterLogin');
          return next({ path });  
        }
			} else {
        // redirect to client /oauth/login not api in they are not already there
        if (window.location.pathname !== '/account/login_management') {
          window.location.href  = `${window.location.origin}/account/login_management`;
        }
      }
		} catch (e) {
			console.log('Error', e);
		}
	}
  if (!isAuthenticated && !isOpenAccess(to)) {
    
    if (isFound(to)) {
      localStorage.setItem('pathToLoadAfterLogin', to.path);
    }
    return next({name: IS_MZ_BRANDING ? 'Login' : 'Management Login'});
  }else{

  }
  if(to.path=='/offers/create'||to.path=='/offers/my-tasks'){
    if(ActiveUser.user.role<2){
      return next({name:from.name});
   }
  }
  if(to.path=='/offers/create'){
    if(ActiveUser.user.role<2){
      return next({name:from.name});
   }
  }
  if(to.path=="/offers/all" || to.path=="/country" || to.path=="/get-in-touch"){
      const {data} = await FilesService.getStorageToken();
    console.log(data.destinations_token)
    console.log(data.media_token)
  
      store.commit('setToken',data.destinations_token)
      store.commit('setMediaToken',data.media_token)

  }
  return next()
});
export default router

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Home',
          component: Home
        },
        {
          path: 'about',
          name: 'About',
          component: About
        },
        {
          path: 'privacy',
          name: 'Privacy',
          component: Privacy
        },
        {
          path: 'get-informed',
          name: 'Get Informed',
          component: Information
        },
        {
          path: 'destinations',
          name: 'Destinations',
          component: Destinations
        },
        {
          path: 'country/:id?',
          name: 'Country',
          component: Country
        },
        {
          path: 'offers',
          name: 'Offers',
          component: Offers
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile
        },
        {
          path: 'jobPage',
          name: 'Job Page',
          component: JobPage
        },
        {
          path: 'flyers',
          name: 'Flyers',
          component: Flyers
        },
        {
          path: 'flyer/:id',
          name: 'Flyer',
          component: Flyer
        },
        {
          path: 'count-me-in',
          name: 'Count Me In',
          component: MeIn
        },
        {
          path: 'get-in-touch',
          name: 'Get In Touch',
          component: GetInTouch
        },
        {
          path: '/offers',
          meta: {
            isOpenAccess: true,
          },
          component: Offers,
          children: [
            {
              path: 'all',
              name: 'offers',
              component: ListOffers,
            },
            {
              path: 'saved',
              name: 'Saved Offers',
              component: SavedOffers
            },
            {
              path: ':id/applicants',
              name: 'Applicants',
              component: Applicants,
            },
            {
              path: ':id/interested',
              name: 'INTERESTED',
              component: Interested,
            },
            {
              path: 'create',
              name: 'Post an Offer',
              component: CreateOffer,
            },
            {
              path: 'my-tasks',
              name: 'My Tasks',
              component: MyTasks,
            },
            
            {
              path: 'offer/:id',
              name: 'Offer Details',
              component: OfferDetails,
            },
          ],
        },
        /* {
          path: 'users',
          meta: {
            label: 'Users'
          },
          component: {
            render() {
              return h(resolveComponent("router-view"));
            },
          },
          children: [
            {
              path: '',
              name: 'Users',
              component: Users
            },
            {
              path: ':id',
              meta: {
                label: 'User Details'
              },
              name: 'User',
              component: User
            }
          ]
        }, */
      ]
    },
    {
      path: '/oauth',
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: '',
          name: 'OAuth Login',
          component: Login,
        },
      ],
    },
   
    {
      path: '/account',
      name: 'account',
      meta: {
        isOpenAccess: true,
      },
      component: AccountContainer,
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login,
        },
        {
          path: 'forgot',
          name: 'Forgot password',
          component: ForgotPassword,
        },
        {
          path: 'reset/:token',
          name: 'Password reset',
          component: ResetPassword,
        },
        {
          path: 'login_management',
          name: 'Management Login',
          component: Login,
          props: {
            show_email_login: true
          }
        },
       
      ],
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render() {
          return h(resolveComponent("router-view"));
        },
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
      ]
    },
    {
      path: "/:pathMatch(.*)*",
      component: TheContainer,
      children: [
        {
          path: '',
          name: '',
          component: Home,
        },
      ]
    }
  ]
}

