import { createStore } from 'vuex'
import { ROLES } from './roles'
const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  alert: {
    show: false,
    color: "success",
    duration: 5000,
    message: "",
  },
  activeUser:{
    role:null,
    grade:'junior'
  },
  office:'',
  token:'',
  mediaToken:'',
  filters:{
    keyword: '',
    level: [],
    countries: [],
    serviceLine: [],
    period: '',
  }
}

const mutations = {
  setOffice(state,office){
    state.office=office
  },
  setUserRole(state,role){
    state.activeUser.role= role;
  },
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  setAlert(state, config) {
    state.alert = { ...state.alert, ...config, show: true };
  },
  hideAlert() {
    state.alert.show = false;
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  setFilter(state,text,start,end,level,countries,services){
    state.filters['keyword']=text;
    state.filters['start']=start;
    state.filters['end']=end;
    state.filters['level']= level
    state.filters['countries']= countries
    state.filters['services']=services
  },
  setToken(state,token){
    state.token=token
  },
  setMediaToken(state,token) {
     state.mediaToken=token ;
  }


}

export default createStore({
  state,
  mutations
})

